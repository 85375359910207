import { render, staticRenderFns } from "./LoginAdmin1.vue?vue&type=template&id=50691eff&scoped=true&"
import script from "./LoginAdmin1.vue?vue&type=script&lang=js&"
export * from "./LoginAdmin1.vue?vue&type=script&lang=js&"
import style0 from "./LoginAdmin1.vue?vue&type=style&index=0&id=50691eff&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50691eff",
  null
  
)

export default component.exports